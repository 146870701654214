import Vue from "vue"
import VueSweetalert2 from "vue-sweetalert2"

Vue.use(VueSweetalert2)

const Swal = Vue.swal

const Toast = Swal.mixin({
    toast: false,
    position: 'center',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

export default Toast