import axios from 'axios'

const http = axios.create({
    baseURL: 'https://helpmeti.paraisomodabebe.com.br/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',                
    },
    
})
http.interceptors.request.use(function(config){
    let token = false;
    if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`
    }
    return config
},function(error){return Promise.reject(error)})

export default http