<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p class="mb-4">
      Aqui você poderá validar se o titular de um CPF é funcionário do Grupo
      Paraíso.
    </p>
    <b-container class="mb-5">
      <b-row class="justify-content-md-center">
        <b-col col lg="5">
          <b-form>
            <b-input-group>
              <b-form-input
                v-model="cpf"
                autofocus
                size="lg"
                v-mask="'###.###.###-##'"
                placeholder="informe um CPF para validar"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="validar" variant="primary" v-if="!validando">
                  Validar CPF
                </b-button>
                <b-button variant="primary" disabled v-else>
                  <b-spinner></b-spinner>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <ul>
      <li>
        <a href="#"  rel="noopener"
          >T.I. Grupo Paraíso</a>
      </li>
      <li>
        <a href="#" v-on:click="privacityShow"  rel="noopener">Política de privacidade</a>
      </li>      
      <li>
        <a href="mailto:parceria@grupoparaisooficial.com.br"  rel="noopener">
          Contato
        </a>
      </li>      
    </ul>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import Toast from '@/alerts'
export default {
  name: "Validador",
  props: {
    msg: String,
  },
  data(){
    return {
      validando:false,      
      cpf:null
    }
  },
  methods: {
    validar(){
      this.validando = true
       this.$http.post('api/validar-funcionario',{"cpf":this.cpf})
            .then(response => {
               let message = typeof response.data === 'object' && response.data.length > 0 ? response.data[0] : 'Sucesso!';
               this.validando = false;
               Toast.fire({
                 icon: 'success',
                 title: 'Sucesso!',
                 text: message
               })
            })
            .catch(err => {
              let icon = 'error'
              let title = 'Oops!'
              let message = typeof err.response.data === 'object' && err.response.data.length>0 ? err.response.data[0] : 'Erro desconhecido!'
               if (err.response.status === 401) {
                 icon = 'warning'                 
                 title = 'Não é mais funcionário!'              
              } 
              Toast.fire({
                  icon: icon,
                  title: title,
                  text: message
                  })                        
                this.validando = false                
            })
    },
     privacityShow() {
        this.$swal({
              title: '<i>Política de privacidade</i>',          
          html:
            '<ol class="text-left" style="font-size:14px;">'+
            '<li class="mb-3">A identificação solicitada pelo lojista tem a mera finalidade de consulta de “Cadastro Ativo” do colaborador do Grupo Paraiso.'+
            ' Assim, ao informar seus dados, o colaborador não criará nenhum tipo de vínculo e/ou registro sistêmico com o comercio parceiro. </li>'+
            '<li class="mb-3">O Grupo Paraíso utiliza o CPF informado nesta página, apenas para consultar se o mesmo consta na base de dados de colaboradores.'+
            ' Não realizando nenhum tipo de tratamento e/ou armazenamento do dado informado.</li>'+
            '<li>O Grupo Paraíso, não se responsabiliza pela coleta, armazenamento, processamento e quaisquer outras tratativas realizadas'+
            ' pelos parceiros com os dados a eles fornecidos pelos colaboradores do Grupo Paraíso.</li>'+
            '</ol>',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: true,
        })
      }
  },
  directives: {mask}
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;    
}
li {
  display: inline-block;
  margin: 0 20px;
}
a {
  color: #909091;
}
</style>
