import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'

import './app.scss'

import http from '@/http'

Vue.config.productionTip = false
Vue.prototype.$http = http

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)

new Vue({
  render: h => h(App),
}).$mount('#app')
