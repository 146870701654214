<template>
  <div id="app">
    <img alt="Logo Parceiro Forte" title="Programa de benefícios Grupo Paraíso" src="./assets/g898.png">
    <link rel="shortcut icon" type="image/png" href="./assets/favicon.png"/>
    <Validador msg="Validar funcionário ativo"/>
  </div>
</template>

<script>
import Validador from './components/Validador.vue'

export default {
  name: 'App',
  components: {
    Validador
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;  
}
#app img {
  box-sizing: border-box;
  width: 40%;
  margin-bottom: 2rem;
}
</style>
